/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  margin-top: 2em;
}

@include bp(540px) {
  .c-footer {
    margin-top: 3em;
  }
}
