/* ==========================================================================
   #LOGO
   ========================================================================== */

.c-logo {
  display: block;
  margin: auto;
  max-width: 410px;
}

.c-logo__media {
  position: relative;
}

.c-logo__caption {
  margin-top: 0.5em;
}

.c-logo__media:after {
  content: '';
  display: block;
}

.c-logo svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-logo--jhs .c-logo__media:after {
  padding-top: (134.92/454) * 100%;
}
