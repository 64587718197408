/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  background: #fff;
  color: $color-blue;
  font-family: $font-sans-serif;
  font-size: 18px;
  line-height: 1.5;
  min-height: 100%;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-align: center;
  display: flex;
}

@include bp(540px) {
  html {
    font-size: 22px;
  }
}

