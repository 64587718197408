/* ==========================================================================
   #TEXT
   ========================================================================== */

.c-text {
  margin: 40px auto 0;
  max-width: 420px;
}

.c-text p:last-child {
  margin-bottom: 0;
}

@include bp(540px) {
  .c-text {
    max-width: 520px;
  }
}
