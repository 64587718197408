/* ==========================================================================
   #MAIN
   ========================================================================== */

.c-main {
  margin: 80px auto;
  width: calc(100vw - 40px);
  max-width: 560px;
}

@include bp(large) {
  .c-main {
    max-width: 1200px;
    width: calc(100vw - 80px);
  }
}
